import { useAuth0 } from "@auth0/auth0-react";
import "font-awesome/css/font-awesome.min.css";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Account from "./components/Account.js";
import Home from "./components/Home.js";
import LoadSuccess from "./components/LoadSuccess.js";
import Publishing from "./components/Publishing";
import Royalties from "./components/Royalties.js";
import Sidebar from "./components/Sidebar.js";
import Submissions from "./components/Submissions.js";
import Support from "./components/Support.js";
import Uploads from "./components/Uploads.js";

export default function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    loginWithRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return;
  }

  if (!isAuthenticated) {
    return console.log("Redirecting to login...");
  }

  return (
      <BrowserRouter>
        <div className="app-container">
          <Sidebar />
          <LoadSuccess />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/royalties" element={<Royalties />} />
            <Route path="/submissions" element={<Submissions />} />
            <Route path="/support" element={<Support />} />
            <Route path="/uploads" element={<Uploads />} />
            <Route path="/account" element={<Account />} />
            <Route path="/publishing" element={<Publishing />} />
          </Routes>
        </div>
      </BrowserRouter>
  );
}
