import React from "react";
import "./Submissions.css";

export default function Submissions() {
  return (
    <div class="submissions">
      <h1>Submit Content & Data</h1>
      <br></br>
      <ul>
        <li>
          <a href="https://forms.gle/TnbJXXJ6W4t2fTM38">Submit Lyrics</a>
        </li>
        <li>
          <a href="https://forms.gle/u4NtRTbYESrLYeMCA">Credits Submissions</a>
        </li>
        <li>
          <a href="https://forms.gle/iEQzFY4MDWgTQwnz8">Content Updates</a>
        </li>
        <li>
          <a href="https://forms.gle/Rjy1gLiivtHeg9FW7">
            YouTube/TikTok Claims
          </a>
        </li>
        <br></br>
        <h1>Send Export Requests</h1>
        <br></br>
        <li>
          <a href="https://forms.gle/qE2ZXnBC67j36U6e6">
            Request Content Update
          </a>
        </li>
        <li>
          <a href="mailto:content-ops@catapultrecordings.com">
            Request Metadata Export (via Email Request)
          </a>
        </li>
        <li>
          <a href="https://forms.gle/9TwFZkkhxFc9r3qH6">
            Start a Nielsen Request
          </a>
        </li>
        <li>
          <a href="mailto:content-ops@catapultrecordings.com">
            CWR Works Export (via Email Request)
          </a>
        </li>
      </ul>
    </div>
  );
}
