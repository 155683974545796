import { useAuth0 } from "@auth0/auth0-react";

import React from "react";
import { NavLink } from "react-router-dom";
import RenderLabelMetadata from "./RenderLabelMetadata.js";
import "./Sidebar.css";

const Sidebar = () => {
  const { user, logout } = useAuth0();

  console.log(user);

  return (
      <nav>
        <div className="sidebar-container">
          <div className="logo">
            <img
              src="https://cdn.catapultrecordings.com/connect/catapultlogo.png"
              alt="Catapult Logo"
            />
          </div>
          <ul>
            <li>
              <NavLink to="/" activeClassName="active" className="menu-item">
                <i className="fa fa-home menu-item-icon"></i>Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/royalties"
                activeClassName="active"
                className="menu-item"
              >
                <i className="fa fa-dollar menu-item-icon"></i>Royalties
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/submissions"
                activeClassName="active"
                className="menu-item"
              >
                <i className="fa fa-plus menu-item-icon"></i>Content
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/uploads"
                activeClassName="active"
                className="menu-item"
              >
                <i className="fa fa-upload menu-item-icon"></i>Uploads
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/publishing"
                activeClassName="active"
                className="menu-item"
              >
                <i className="fa fa-microphone menu-item-icon"></i>Publishing
              </NavLink>
            </li>
            <hr class="custom-line" />
          </ul>
          <hr class="custom-line" />
          <div class="accountinfo">
            <h3><h3>{user.name}</h3></h3>
            <i>
              <RenderLabelMetadata />
            </i>
            <ul>
              <li>
                <NavLink
                  to="/account"
                  activeClassName="active"
                  className="menu-item"
                >
                  <i className="fa fa-user menu-item-icon"></i>
                  Account
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/support"
                  activeClassName="active"
                  className="menu-item"
                >
                  <i className="fa fa-question-circle menu-item-icon"></i>
                  Support
                </NavLink>
              </li>
            </ul>
            <br />
              <button onClick={() => logout()}>Log Out</button>
          </div>
          <div className="footer">
            &copy; 2023 Catapult Recordings.<br></br>All rights reserved.
          </div>
        </div>
      </nav>
    );
};
console.log("navigation loaded")

export default Sidebar;
