import React from "react";
import "./Royalties.css";

export default function Royalties() {
  return (
    <div class="royaltiesdash">
      <h1>Royalties</h1>
      <br></br>
      <h3 class="rd-quicklinks-head">Quick Links</h3>
      <br></br>
      <nav class="rd-nav">
        <ul class="rd-menu">
          <li class="rd-menu-item">
            <a href="https://infinitecatalog.com/login" class="rd-menu-link">
              Statements @ IC
            </a>
          </li>
          <li class="rd-menu-item">
            <a
              href="https://connect.stripe.com/express_login"
              class="rd-menu-link"
            >
              Payouts @ Stripe
            </a>
          </li>
          <li class="rd-menu-item">
            <a href="https://forms.gle/hSeXTGHALmwvdDde7" class="rd-menu-link">
              Disputes
            </a>
          </li>
        </ul>
      </nav>
      <section class="rd-stripe-express">
        <h3 class="rd-section-title">
          Download the Stripe Express app to manage your payouts.
        </h3>
        <br></br>
        <a href="https://apps.apple.com/us/app/stripe-express/id1560214813">
          <img
            src="https://cdn.catapultrecordings.com/connect/appstoredownload.png"
            alt="Stripe Express"
            width="150px"
            class="rd-stripe-express-img"
          />
        </a>
      </section>
    </div>
  );
}
