import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./index.css";

ReactDOM.render(
  <Auth0Provider
    domain="catapult.us.auth0.com"
    clientId="452xqv4ODp9gFxZicoGKxzFQK3OFZovC"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://catapult.us.auth0.com/api/v2/",
      scope: "openid profile email read:current_user_metadata read:current_user read:app_metadata",
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
console.log("primaryjs loaded");