import { UploadButton } from "react-uploader";
import Swal from "sweetalert2";
import { Uploader } from "uploader";

export default function UploadBtn() {
  const uploader = Uploader({ apiKey: "public_kW15bBUGCkGDK7Gqgz6sNt2nnvPP" });

  return (
    <UploadButton
      uploader={uploader}
      options={{ multi: true }}
      onComplete={(files) =>
        Swal.fire({
          title: "Woop woop!",
          text: "Your files have been uploaded.",
          icon: "success",
          confirmButtonText: "Cool!",
        })
      }
    >
      {({ onClick }) => <button onClick={onClick}>Upload a file...</button>}
    </UploadButton>
  );
}
