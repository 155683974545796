import React from "react";
import "./Support.css";

export default function Support() {
  return (
    <div class="support">
      <h1>Get Support</h1>
      <br></br>
      <h3>Email Support</h3>
      <ul>
        <li>
          <a href="mailto:content-ops@catapultrecordings.com">
            Content Operations
          </a>
        </li>
        <li>
          <a href="mailto:shop@catapultrecordings.com">
            Shop+Physical Product Support
          </a>
        </li>
        <li>
          <a href="mailto:connect@catapultrecordingsgroup.com">
            Connect Portal Support
          </a>
        </li>
        <li>
          For royalty and accounting questions, please contact your partner
          manager.
        </li>
      </ul>
      <br></br>
      <h3>Phone Support</h3>
      <a href="tel:4052764580">405-276-4580</a>
      <br></br>
      <br></br>
      <h3>Resources</h3>
      <ul>
        <li>
          <a href="https://help.apple.com/itc/musicstyleguide/en.lproj/static.html">
            Apple Style Guide
          </a>
        </li>
        <li>
          <a href="https://support.catapultrecordings.com">
            Help Center & Documentation
          </a>
        </li>
        <li>
          <a href="https://support.catapultrecordings.com">
            2023 Royalty Payment Schedule
          </a>
        </li>
      </ul>
    </div>
  );
}
