import React from "react";
import "./Publishing.css";

export default function Publishing() {
  return (
    <div className="publishing">
      <h1>Publishing</h1>
      <div className="publishing-container">
        <div>
          <h2>Submit a Setlist</h2>
          <p>
            Recent perfromances can be submitted for setlist approval to
            generate publishing payments.
          </p>
          <button
          >
            Coming Soon
          </button>
          <br />
        </div>
        <div>
          <h2>Update Details</h2>
          <p>
            Request an update of your personal information registered with ASCAP
            or BMI.
          </p>
          <button
          >
            Coming Soon
          </button>
          <br />
        </div>
        <div>
          <h2>CWR Tools</h2>
          <p>
            Reqeuest a CWR file for a specific release or download the CWR for
            your entire catalog.
          </p>
          <button
          >
            Coming Soon
          </button>
          <br />
        </div>
      </div>
    </div>
  );
}
