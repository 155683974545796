import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import "./Account.css";

const RenderLabelMetadata = () => {
  const { user, getIdTokenClaims } = useAuth0();
  const [assignedLabel, setAssignedLabel] = useState(null);

  useEffect(() => {
    const fetchUserMetadata = async () => {
      const claims = await getIdTokenClaims();
      const label = claims["https://myapp.example.com/assignedlabel"];
      setAssignedLabel(label);
    };

    if (user) {
      fetchUserMetadata();
    }
  }, [user, getIdTokenClaims]);

  return (
    <div>
      {assignedLabel && (
        <>
          {Object.entries(assignedLabel).map(([key, value]) => (
            <p key={key}>{value}</p>
          ))}
        </>
      )}
    </div>
  );
};

export default RenderLabelMetadata;
