import React from "react";
import UploadBtn from "./UploadBtn.js";
import "./Uploads.css";

export default function Uploads() {
  return (
    <div className="uploads">
      <h1>Start an Upload</h1>
      <div className="upload-container">
        <div>
          <h2>Audio/Video</h2>
          <p>Use DISCO to upload raw audio/video files.</p>
          <button
            onClick={() =>
              window.open("https://s.disco.ac/ogxisklcwhlf", "_blank")
            }
          >
            Upload Files
          </button>
          <br />
        </div>
        <div>
          <h2>CI Data Transfer</h2>
          <p>
            Use the Consolidated Independent Excel template to submit metadata
            for uploaded audio/video products.
          </p>
          <button
            onClick={() =>
              window.open(
                "https://cdn.catapultrecordings.com/connect/ci-audio.xlsx",
                "_blank"
              )
            }
          >
            Download AUDIO Template
          </button>
          <br />
          <button
            onClick={() =>
              window.open(
                "https://cdn.catapultrecordings.com/connect/ci-video.xlsx",
                "_blank"
              )
            }
          >
            Download VIDEO Template
          </button>
          <br />
          <UploadBtn />
          <br />
        </div>
        <div>
          <h2>MDX</h2>
          <p>
            Submit an Excel spreadsheet containing valid Music Data Exchange
            metadata.
          </p>
          <button
            onClick={() =>
              window.open(
                "https://cdn.catapultrecordings.com/connect/mdx.xlsx",
                "_blank"
              )
            }
          >
            Download Template
          </button>
          <br />
          <UploadBtn />
          <br />
        </div>
        <div>
          <h2>CWR</h2>
          <p>
            Convert a CSV file to a Common Works Registration file for music
            PROs. Limited to 100 works. Supported CWR versions are: 2.1, 2.2,
            3.0 and 3.1. Export your XLSX as a CSV for upload!
          </p>
          <button
            onClick={() =>
              window.open(
                "https://cdn.catapultrecordings.com/connect/cwr.xlsx",
                "_blank"
              )
            }
          >
            Download Template
          </button>
          <br />
          <UploadBtn />
          <br />
        </div>
      </div>
    </div>
  );
}
