import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <div class="home">
      <h1>Welcome to Catapult Connect</h1>
      <p>Access internal resources, applications, and forms.</p>
      <p>
        The new portal for everything Catapult-related. From delivery to
        royalties, you can find mostly everything here!
      </p>
      <p>
        This is our onboarding guide to make sure you've got your new account
        setup perfectly. If your partner manager has completed this onboarding
        with you, feel free to skip.
      </p>

      <h2>Getting Started</h2>
      <ol>
        <li>
          <h3>Deliver content with file Uploads or Custom Templates</h3>
          <p>
            We now support MDX, CWR, and CI, along with our standard file
            uploader for audio and video.
          </p>
        </li>
        <li>
          <h3>Submit your lyrics, credits, and any updates</h3>
          <p>
            Use our handy forms to deliver lyrics, liner notes, and
            contributions to your releases.
          </p>
        </li>
        <li>
          <h3>Sign up for direct deposit royalties with Stripe Express</h3>
          <p>
            Stripe is our payment processor for royalties and sales. Be sure to
            create your account ASAP!
          </p>
        </li>
        <li>
          <h3>Drill down into sales and royalty data</h3>
          <p>
            Once your Stripe account has been verified, you'll get an email from
            Infinite Catalog, our statement provider. Set up your account with
            them to access data and statements.
          </p>
        </li>
        <li>
          <h3>Make requests and support tickets</h3>
          <p>
            Contact our Contact Operations team, request a metadata export,
            start a Nielsen request, and more in our Requests & Support sections
            of the Access Panel.
          </p>
        </li>
      </ol>
    </div>
  );
}
