import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import "./Account.css";

export default function Account() {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [appMetadata, setAppMetadata] = useState(null);

  useEffect(() => {
    const fetchAppMetadata = async () => {
      const claims = await getIdTokenClaims();
      const metadata = claims["https://myapp.example.com/app_metadata"];
      setAppMetadata(metadata);
    };

    if (user) {
      fetchAppMetadata();
    }
  }, [user, getIdTokenClaims]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div class="account">
        <h1>Manage Account</h1>
        <br></br>
        <table>
          <tr>
            <th>
              <h2>Information</h2>
            </th>
            <th></th>
          </tr>
          <tr>
            <td>
              <strong>Your Name:</strong>
            </td>
            <td>{user.name}</td>
          </tr>
          <tr>
            <td>
              <strong>Your Email:</strong>
            </td>
            <td>{user.email}</td>
          </tr>
          {appMetadata && (
            <>
              <tr>
                <td>
                  <strong>Payee ID:</strong>
                </td>
                <td>{appMetadata.icpid}</td>
              </tr>
              <tr>
                <td>
                  <strong>IC Account:</strong>
                </td>
                <td>{appMetadata.ictag}</td>
              </tr>
              <tr>
                <td>
                  <strong>Payout Method:</strong>
                </td>
                <td>{appMetadata.payoutmethod}</td>
              </tr>
              <tr>
                <td>
                  <strong>Stripe Connect ID:</strong>
                </td>
                <td>{appMetadata.stripeid}</td>
              </tr>
            </>
          )}
        </table>
        <br></br>
        <p class="accountcontactsupport">
          Contact support to change your details.
        </p>
        <h2>Manage Subscriptions</h2>
        <br></br>
        <button onClick={() => window.open('https://billing.stripe.com/p/login/3cseY668W2aQglqbII', '_blank')}>
          Go to Stripe Billing
        </button>
      </div>
    )
  );
}